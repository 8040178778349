import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TeamSmiles from "../components/TeamSmiles"

import {
  FadeInTop,
  FadeInRight,
  gsap,
  useScrollImageReveal,
} from "../components/animations"
import { useMediaQuery } from "../helpers/hooks"
import { graphql, useStaticQuery } from "gatsby"

import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"
import Markdown from "marked-react"

import EmpowerBrandsBlock from "../components/EmpowerBrandsBlock"

// our collective experience logos
import LogoBHM from "../assets/images/logo-bhm.svg"
import LogoChipper from "../assets/images/logo-chipper.svg"
import LogoDotts from "../assets/images/logo-dotts.svg"
import LogoBrunelHive from "../assets/images/logo-brunel-hive.svg"
import LogoCocaCola from "../assets/images/logo-coca-cola.svg"
import LogoDesperados from "../assets/images/logo-desperados.svg"
import LogoDhiraj from "../assets/images/logo-dhiraj.svg"
import LogoHeineken from "../assets/images/logo-heineken.svg"
import LogoLegend from "../assets/images/logo-legend.png"
import LogoLiberti from "../assets/images/logo-liberti.svg"
import LogoMoetChandon from "../assets/images/logo-moet-and-chandon.svg"
import LogoSME100Africa from "../assets/images/logo-SME100-africa.svg"
import LogoStarRadler from "../assets/images/logo-star-radler.png"
import { PortableText } from "@portabletext/react"
import { data } from "autoprefixer"

const TextCycle = () => {
  const index = useRef(0)
  const container = useRef(null)
  const items = ["brands", "people", "goals"]

  useEffect(() => {
    let children = container.current.querySelectorAll("span")

    const switchText = () => {
      let i = index.current % items.length
      children.forEach(child => {
        child.classList.replace("opacity-100", "opacity-0")
        child.classList.remove("relative")
      })
      children[i].classList.add("relative")
      children[i].classList.replace("opacity-0", "opacity-100")
      index.current++
    }

    // for the first time only to show initial text
    setTimeout(() => {
      children[0].classList.add("relative")
      children[0].classList.replace("opacity-0", "opacity-100")
    }, 500)

    let show = setInterval(switchText, 2000)

    return () => {
      clearInterval(show)
    }
  }, [])

  return (
    <span className="about-text-cycle relative" ref={container}>
      {items.map((item, i) => (
        <span
          className="absolute opacity-0 left-0"
          key={item}
          style={{ transition: "opacity 1000ms ease 0s", zIndex: i }}
        >
          {item}
        </span>
      ))}
    </span>
  )
}

const Module1 = ({ data }) => {
  return (
    <section className="c-container-full pt-24 lg:pt-40">
      <FadeInTop delay={0.5}>
        <h1 className="text-4xl md:text-5xl lg:text-6xl xl:text-8xl font-headings mb-9 lg:mb-16 leading-tight md:leading-[1.3] lg:leading-tight xl:leading-tight">
          We empower
          <br /> ambitious {"  "}
          <TextCycle />
        </h1>
      </FadeInTop>
      <FadeInTop delay={1.3}>
        <p className="text-justify text-lg lg:text-xl lg:leading-normal max-w-[532px] sm:ml-[25%] md:ml-[40%]">
          {data.paragraph}
        </p>
      </FadeInTop>
    </section>
  )
}

const Module2 = ({ data }) => {
  const serializerComponents = {
    block: {
      normal: ({ children }) => (
        <FadeInTop>
          <p>{children}</p>
        </FadeInTop>
      ),
    },
  }

  return (
    <section className="bg-white text-black pt-28 md:pt-32 lg:pt-48 xl:pt-56 pb-12 lg:pb-[400px]">
      <div className="c-container-full lg:flex">
        <div className="lg:w-[40%] shrink-0">
          <FadeInTop>
            <h2 className="text-3xl md:text-5xl xl:text-6xl lg:leading-tight font-headings mb-5 lg:max-w-[170px]">
              {data.heading}
            </h2>
          </FadeInTop>
        </div>
        <div className="space-y-6 text-lg text-justify md:max-w-[680px] ">
          <PortableText
            value={data._rawParagraphs}
            components={serializerComponents}
          />
        </div>
      </div>
    </section>
  )
}

const Module3 = ({ data }) => {
  let imgContainer = useRef(null)
  let mediaQ = useMediaQuery("(max-width: 767px)")

  useEffect(() => {
    if (mediaQ) {
      let img = imgContainer.current.querySelector(".js-image-parallax")
      let imgHeight = img.offsetHeight
      let imgContainerHeight = imgContainer.current.offsetHeight
      let imgHeightCalc = Math.max(
        imgContainerHeight,
        document.body.clientWidth * 1.465116279069767
      )
      let parallaxPercent = 100 - (imgContainerHeight / imgHeightCalc) * 100

      // console.log({ imgContainerHeight, imgHeightCalc, parallaxPercent })

      gsap
        .timeline({
          scrollTrigger: {
            trigger: imgContainer.current,
            start: "50% bottom",
            end: "bottom top",
            scrub: 0.5,
          },
        })
        .to(img, {
          yPercent: -parallaxPercent,
        })
    }
  }, [mediaQ])

  const content = [
    {
      title: "Our Mission",
      paragraph: data.mission,
    },
    {
      title: "Our Vision",
      paragraph: data.vision,
    },
    {
      title: "Our Promise",
      paragraph: data.promise,
    },
  ]

  return (
    <section className="c-container-full px-0 md:px-4 lg:px-0 pt-12 md:pb-12 lg:pb-20 lg:mt-[-245px]">
      <div className="md:flex">
        <div className="space-y-12 lg:divide-y divide-[#323232] md:flex flex-col justify-between mb-12 md:mb-0 px-4 md:px-0 md:mr-6 lg:mr-8">
          {content.map(({ title, paragraph }) => (
            <div key={title} className="c-mission-vision lg:flex">
              <FadeInTop>
                <div className="basis-2/5">
                  <h2 className="font-headings text-[#666] text-xl lg:text-4xl mb-4 lg:w-min">
                    {title}
                  </h2>
                </div>
              </FadeInTop>
              <FadeInTop delay={0.3}>
                <p className="text-lg lg:text-xl leading-normal lg:leading-normal text-justify basis-3/5">
                  {paragraph}
                </p>
              </FadeInTop>
            </div>
          ))}
        </div>
        <div className="h-[630px] md:h-full overflow-hidden" ref={imgContainer}>
          <div className="js-image-parallax js-img-reveal">
            <StaticImage
              src="../assets/images/burna.jpg"
              alt="burna boy"
              objectFit="cover"
              className="h-full"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const Module4 = ({ data }) => {
  return (
    <section className="bg-white text-black pt-12 md:pb-12 lg:pt-24 lg:pb-24">
      <div className="c-container-full px-0 md:px-4 xl:flex">
        <div className="xl:w-1/4">
          <FadeInTop>
            <h2 className="xl:w-min px-4 md:px-0 font-headings text-3xl md:text-5xl xl:text-6xl mb-12">
              {data.heading}
            </h2>
          </FadeInTop>
        </div>
        <div className="space-y-12 md:space-y-0 md:grid grid-rows-2 grid-cols-[repeat(2,_minmax(0,_360px))] md:gap-8 xl:gap-16">
          {data.items.map(({ title, description }) => (
            <div className="px-4 md:px-0 lg:w-[360px]">
              <FadeInTop>
                <h3 className="font-headings text-[#666] text-xl lg:text-2xl xl:text-3xl mb-4">
                  {title}
                </h3>
              </FadeInTop>
              <FadeInTop delay={0.4}>
                <p className="text-justify text-lg leading-normal">
                  {description}
                </p>
              </FadeInTop>
            </div>
          ))}
          <div className="js-img-reveal">
            <StaticImage
              src="../assets/images/vivian-x-bbn-housemate.jpg"
              alt="vivan evboren and bbnaija housemate"
              objectFit="cover"
              objectPosition="top"
              className="w-full md:w-auto md:h-[180px]"
              placeholder="none"
              width={337}
              height={270}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const OurLeadershipSection = ({ data }) => {
  return (
    <section id="leadership" className="c-container-full py-12 lg:py-24">
      <FadeInTop>
        <h2 className="font-headings text-3xl mb-12 lg:mb-16 sm:text-4xl lg:text-6xl lg:w-min ">
          Our Management
        </h2>
      </FadeInTop>
      <div className="sm:flex flex-wrap sm:-mx-4 pt-8 sm:pt-16 2xl:pt-24">
        {data.map((datum, i) => (
          <FadeInTop delay={(i % 3) * 0.25} threshold={0.15}>
            <TeamMemberCard data={datum} />
          </FadeInTop>
        ))}
      </div>
    </section>
  )
}

const TeamMemberCard = React.forwardRef(
  (
    { className, data: { firstname, lastname, linkedin, picture, position } },
    ref
  ) => {
    const img = picture.asset.gatsbyImageData

    return (
      <div
        className={`sm:w-1/2 lg:w-1/3 sm:px-4 mb-20 sm:mb-20 2xl:mb-24 ${className}`}
        ref={ref}
      >
        <div className="relative">
          <GatsbyImage
            image={img}
            alt={`${firstname} ${lastname}`}
            imgClassName="w-full"
            className="w-full"
            objectPosition="top"
          />
        </div>
        <div className="px-6 py-6 bg-[#0e0e0e] overflow-auto">
          <h4 className="text-4xl leading-none font-headings w-min mb-3">
            {firstname} <span className="text-[#666]">{lastname}</span>
          </h4>
          <a className="inline-block mb-2" href={linkedin} target="_blank">
            <svg viewBox="207.361 170.504 24 24" width="24" height="24">
              <path
                d="M 226.361 170.504 L 212.361 170.504 C 209.6 170.504 207.361 172.743 207.361 175.504 L 207.361 189.504 C 207.361 192.265 209.6 194.504 212.361 194.504 L 226.361 194.504 C 229.123 194.504 231.361 192.265 231.361 189.504 L 231.361 175.504 C 231.361 172.743 229.123 170.504 226.361 170.504 Z M 215.361 189.504 L 212.361 189.504 L 212.361 178.504 L 215.361 178.504 L 215.361 189.504 Z M 213.861 177.236 C 212.895 177.236 212.111 176.446 212.111 175.472 C 212.111 174.498 212.895 173.708 213.861 173.708 C 214.827 173.708 215.611 174.498 215.611 175.472 C 215.611 176.446 214.828 177.236 213.861 177.236 Z M 227.361 189.504 L 224.361 189.504 L 224.361 183.9 C 224.361 180.532 220.361 180.787 220.361 183.9 L 220.361 189.504 L 217.361 189.504 L 217.361 178.504 L 220.361 178.504 L 220.361 180.269 C 221.757 177.683 227.361 177.492 227.361 182.745 L 227.361 189.504 Z"
                style={{ fill: "rgb(153, 153, 153)" }}
              ></path>
            </svg>
          </a>
          <p>{position}</p>
        </div>
      </div>
    )
  }
)

const CollectiveExperienceSection = () => {
  const brandsWrapper = useRef(null)

  useEffect(() => {
    let logos = brandsWrapper.current.querySelectorAll("img")

    gsap
      .timeline({
        scrollTrigger: {
          trigger: brandsWrapper.current,
          start: "20% bottom",
        },
      })
      .from(logos, {
        opacity: 0,
        y: 30,
        ease: "back.out(1.7)",
        duration: 2,
        stagger: 0.088,
      })
  }, [])

  return (
    <section className="bg-white text-black pt-12 lg:pt-24 pb-24">
      <div className="w-fit max-w-full mx-auto">
        <FadeInTop>
          <h2 className="text-3xl mb-12 md:mb-16 lg:mb-24 lg:tracking-wider font-headings px-4 lg:px-0">
            Our Collective Experience
          </h2>
        </FadeInTop>
        <div className="c-brand-logos" ref={brandsWrapper}>
          <img src={LogoBHM} alt="bhm logo" />
          <img src={LogoChipper} alt="bhm logo" />
          <img src={LogoDotts} alt="bhm logo" />
          <img src={LogoBrunelHive} alt="brunel hive logo" />
          <img src={LogoCocaCola} alt="coca cola logo" />
          <img src={LogoDesperados} alt="depserados logo" />
          <img src={LogoDhiraj} alt="dhiraj logo" />
          <img src={LogoHeineken} alt="heineken logo" />
          <img src={LogoLegend} alt="legend logo" />
          <img src={LogoLiberti} alt="liberti logo" />
          <img src={LogoSME100Africa} alt="SME100 africa logo" />
          <img src={LogoStarRadler} alt="star radler logo" />
          <img src={LogoMoetChandon} alt="moet and chandon logo" />
        </div>
      </div>
    </section>
  )
}

const AboutPage = () => {
  useScrollImageReveal()
  const pageCMSData = useStaticQuery(graphql`
    query AboutPageDataQuery {
      AboutPageData: allSanityPageAbout {
        nodes {
          module1 {
            paragraph
          }
          module2 {
            heading
            _rawParagraphs
          }
          module3 {
            mission
            promise
            vision
          }
          module4 {
            heading
            items {
              title
              description
            }
          }
        }
      }

      SEOData: sanityPageAbout {
        SEO {
          title: seoTitle
          description: seoDescription
        }
      }

      TeamMembers: allSanityTeamMember {
        nodes {
          firstname
          lastname
          position
          linkedin
          picture {
            asset {
              gatsbyImageData(width: 480, height: 480)
            }
          }
        }
      }
    }
  `)

  const AboutPageData = pageCMSData.AboutPageData.nodes[0]
  const SEOData = pageCMSData.SEOData.SEO

  return (
    <Layout>
      <Seo title={SEOData.title} description={SEOData.description} />
      <Module1 data={AboutPageData.module1} />
      <TeamSmiles />
      <Module2 data={AboutPageData.module2} />
      <EmpowerBrandsBlock
        backgroundImage={
          <StaticImage
            alt="emmanuel kehinde photo"
            src="../assets/images/empower-brands-ek.jpg"
            objectPosition="top"
            objectFit="cover"
            className="h-full"
          />
        }
      />
      <Module3 data={AboutPageData.module3} />
      <Module4 data={AboutPageData.module4} />
      <OurLeadershipSection data={pageCMSData.TeamMembers.nodes} />
      <CollectiveExperienceSection />
    </Layout>
  )
}
export default AboutPage
